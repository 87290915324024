import React, { useState, useEffect } from 'react';
import '../App.css';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import Fade from '@material-ui/core/Fade';
import { Formik, Form } from 'formik'
import { TextField, SelectField } from './FormElements';
import CheckboxField from './FormElements/CheckboxField'
import RadioField from './FormElements/RadioField'
import DropdownField from './FormElements/DropdownField'
import InputDropdownField from './FormElements/InputDropdownField'
import NumericField from './FormElements/NumericField'
import * as Yup from 'yup';
import {Footer} from './Footer'


function Webview(props) {
    const {config, sendManychatFlow, setManychatFields,setAndSendManychat, MessengerExtensions} = props
    const [formSchema, setFormSchema] = useState({})
    const [formAnswers, setFormAnswers] = useState(undefined);
    const [validationSchema, setValidationSchema] = useState(undefined);
    const [prices, setPrices] = useState({})
    // Only Numeric Input can set price multiplier
    const [priceMultiplier, setPriceMultiplier] = useState(1) 
    const [totalPrice, setTotalPrice] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [showCloseScreen, setShowCloseScreen] = useState(false)

    const theme = useTheme();
    const useStyles = makeStyles((theme) => ({
        content: {
          paddingBottom: '116px',
          minHeight: '100%',
        },
        form: {
          height: '100%',
          position: 'relative',
        },
        fullWidth: {
          width: '100%',
        },
        backdrop: {
          zIndex: theme.zIndex.drawer + 1
        }
      }));
    const classes = useStyles();


    useEffect(() => {
        if(!Object.keys(config).length){return}
        initForm(config);
    }, [config]);

    useEffect(() => {  
      var new_total_price = 0
      for (var price in prices){
        new_total_price = new_total_price + Number(prices[price])
      } 
      setTotalPrice(new_total_price * priceMultiplier)
    }, [prices, priceMultiplier]);

    const initForm = (_config) => {
        let formSchema = _config.form_schema
        setFormSchema(formSchema)
        let _formAnswers = {};
        let _validationSchema = {};
        let init_prices = {}

        for(var key of Object.keys(formSchema)){
            const REQUIRED_ERROR_TEXT="*"
            const TOO_MANY_ERROR_TEXT="⚠️"
            _formAnswers[key] = "";
            // Set Radio Defaults
            if (formSchema[key].type === "radio"){
              _formAnswers[key]= formSchema[key].select_default+""
            }
            
            // Set Numeric defaults
            if (formSchema[key].type === "numeric"){
              _formAnswers[key]= formSchema[key].default
            }

            // Set checkbox to empty list
            if (formSchema[key].type === "checkbox"){
              _formAnswers[key]= []
            }

            // Set dropdown default to first in list
            if (formSchema[key].type === "select"){
              _formAnswers[key]= "0"
            }

            // Set initial price for radio
            if (formSchema[key].has_price && formSchema[key].type === "radio" ){
              init_prices[key] = formSchema[key].options[formSchema[key].select_default] ? formSchema[key].options[formSchema[key].select_default].attributes.price : 0
            }
            
            // Set initial price for dropdown
            if (formSchema[key].has_price && formSchema[key].type === "select" ){
              init_prices[key] = formSchema[key].options[0]?.attributes?.price ? formSchema[key].options[0].attributes.price : 0
            }

            // Set Validations
            if(formSchema[key].type === "text"){
                _validationSchema[key] = Yup.string();
            }else if(formSchema[key].type === "email"){
                _validationSchema[key] = Yup.string().email()
            }else if(formSchema[key].type === "select"){
                if(formSchema[key]['min_selection'] > 0){
                  _validationSchema[key] = Yup.string().required(REQUIRED_ERROR_TEXT) //.oneOf(formSchema[key].options.map(o => o.value));
                }
            }else if(formSchema[key].type === "autocomplete"){
              if(formSchema[key]['min_selection'] > 0){
                _validationSchema[key] = Yup.string().required(REQUIRED_ERROR_TEXT) //.oneOf(formSchema[key].options.map(o => o.value));
              }
            }else if(formSchema[key].type === "numeric"){
              _validationSchema[key] = Yup.number().required().positive().integer()
            }else if(formSchema[key].type === "checkbox"){
              _validationSchema[key] = Yup.array().required().min(formSchema[key]['min_selection'], REQUIRED_ERROR_TEXT).max(formSchema[key]['max_selection'], TOO_MANY_ERROR_TEXT)
            }

            if(formSchema[key].required){
                _validationSchema[key] = _validationSchema[key].required('Required');
            }
        }
        setFormAnswers(_formAnswers);
        setPrices(init_prices)
        setValidationSchema(Yup.object().shape({ ..._validationSchema }));
    }

    const getFormElement = (elementName, elementSchema, setFieldValue) => {
        const props = {
            name: elementName,
            label: elementSchema.label,
            options: elementSchema.options,
            prices: prices,
            currency: config.currency,
            setPrices: setPrices,
            setPriceMultiplier,
            setFieldValue: setFieldValue
        };

        if (elementSchema.type === "text" || elementSchema.type === "email") {
            return <TextField {...props} />
        }

        if (elementSchema.type === "autocomplete") {
          return <InputDropdownField  {...props} />
        }

        if (elementSchema.type === "select") {
            return <DropdownField  {...props} />
        }

        if (elementSchema.type === "radio") {
          return <RadioField  {...props} />
        }

        if (elementSchema.type === "checkbox") {
          return <CheckboxField  {...props} />
        }

        if (elementSchema.type === "numeric") {
          return <NumericField  {...props} min={elementSchema.min} max={elementSchema.max} />
        }

    }

    function closeWindow(){
      setShowCloseScreen(true)
      MessengerExtensions.requestCloseBrowser(
        function success() {
          // webview is closed
        },
        function error(err) {
          console.log(err)
          // This works for Native view on desktop, but not native view on mobile
          // This redirect should work for android
          window.location.href = "https://www.messenger.com/closeWindow/?image_url=https://i.imgur.com/yqLvzUm.png&display_text=Please%20close%20this%20window"
          window.close();
          // setTimeout(function (){
          //   alert("Please close this window.");
          // }, 3000);
        }
      );
    }

    async function onSubmit(values, { resetForm, setStatus }) {
      setSubmitting(true)
      const [setFieldsResponse] = await Promise.all([
        setAndSendManychat(values, config, formSchema, totalPrice, config.submit.button.target, config.submit.button.do_expire)
      ]).then(async ()=> {
      closeWindow()
      });
    }

    return (
        <>
        <Backdrop className={classes.backdrop} open={submitting && !showCloseScreen}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Backdrop className={classes.backdrop} open={showCloseScreen}>
          <Fade in={showCloseScreen} timeout={500}>
            <CheckCircleTwoToneIcon color="primary" style={{fontSize: "15em"}}/>
          </Fade>
        </Backdrop>
        {validationSchema && formAnswers &&
        <Formik
          novalidate=""
          enableReinitialize
          initialValues={formAnswers}
          validateOnMount={true}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          className={`${classes.fullWidth} ${classes.form} ${classes.content}`}
        >
          {({setFieldValue}) => (
          <Form>
            {config.title &&
            <Box style={{ background: theme.palette.primary.main, padding: 16, }}>
              <Typography align="center" variant="h6" color="secondary">
                {config.title}
              </Typography>
            </Box>
            }
            {Object.keys(formSchema).map( (key, ind) => (
                <div key={key} >
                    {getFormElement(key, formSchema[key], setFieldValue)}
                </div>
            ))}
            <Footer
              config={config}
              totalPrice={totalPrice}
            />
          </Form>
          )}
        </Formik>
        
         }
        </>
    );
}

export default Webview;