
import test_config from '../data/test_config';
import test_config_2 from '../data/test_config_2'
import axios from 'axios'
import { fieldToSelect } from 'formik-material-ui';

export function setManychatFields(pageID, 
    userID, 
    values, 
    config, 
    formSchema, 
    totalPrice,
    flowID,
    time, 
    doExpire){
    var newFieldsToSet = []
    for (var form_index in values){
      if (typeof values[form_index] == "string" && values[form_index] !== ""){
        newFieldsToSet.push( {
          field_id: formSchema[""+form_index].mc_field,
          field_type: 'string',
          field_value: formSchema[""+form_index].options[values[""+form_index]].attributes.text,
        })
      }
      // If the value is a list of values, for example, checkboxes
      else if (typeof values[form_index] == "object"){
        let joined_value = []
        for (var form_index_2 in values[form_index]){
          joined_value.push(formSchema[""+form_index].options[values[form_index][form_index_2]].attributes.text)
        }
        newFieldsToSet.push( {
          field_id: formSchema[""+form_index].mc_field,
          field_type: 'string',
          field_value: joined_value.join(formSchema[""+form_index].join_with),
        })
      }
      else {
        newFieldsToSet.push( {
          field_id: formSchema[""+form_index].mc_field,
          field_type: 'number',
          field_value: values[form_index],
        })
      }
    }
    if(config.price_mc_field !== ""){
      newFieldsToSet.push( {
        field_id: config.price_mc_field,
        field_type: 'number',
        field_value: totalPrice,
      })
    }
    if( config.save_all_mc_field !== "" && config.save_all_mc_field !== undefined){
      let joined_value = []
      for (var field in newFieldsToSet){
        console.log(newFieldsToSet[field])
        if(newFieldsToSet[field].field_type === "string" && newFieldsToSet[field].field_value !== ""){
          joined_value.push(newFieldsToSet[field].field_value)
        }
      }
      newFieldsToSet.push( {
        field_id: config.save_all_mc_field,
        field_type: 'string',
        field_value: joined_value.join(", "),
      })
    }
    console.log(newFieldsToSet)
    return postManyChatFields(newFieldsToSet, pageID, userID, flowID, time, doExpire)
  }


async function postManyChatFields(newFieldsToSet, pageID, userID, flowID, time, doExpire ){
  const body = {
                "user_id": userID,
                "page_id": pageID,
                "user_fields":newFieldsToSet,
                "flow_id": flowID,
                "timestamp":time,
                "do_expire": doExpire
            };
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  };
  return axios.post('https://api.botsheets.com/botsheets/v2/webviews/setmanychatfield', body, options)
      .then(response => {
        return {
          success: true,
          message: "success"
        }
      })
      .catch(error => {
          console.log(error);
          return {
            success: false,
            message: error.response.data.message
          }
      });
  }

  /*
export async function sendManychatFlow(pageID, userID, flowID){
  const body = {
                "user_id": userID,
                "page_id": pageID,
                "flow_id":flowID
            };
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  };
  return axios.post('https://api.botsheets.com/botsheets/v2/webviews/sendmanychatflow', body, options)
      .then(response => {
        return {
          success: true,
          message: "success"
        }
      })
      .catch(error => {
          console.log(error);
          return {
            success: false,
            message: error.response.data.message
          }
      });
  }
*/

export async function getConfig(configHash, configName, pageID, userID, time){
  const body = { 
                  "page_id": pageID,
                  "config_hash": configHash,
                  "config_name": configName,
                  "user_id":userID,
                  "time":time
                };
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  };
  return axios.post('https://api.botsheets.com/botsheets/v2/webviews/getconfig', body, options)
      .then(response => {
        return {
          success: true,
          isExpired: response.data.is_expired, 
          message: "success",  
          config: response.data.config
        }
      })
      .catch(error => {
          console.log(error.response);
          return {
            success: false,
            isExpired: false, 
            message: error.response.data.message
          }
      });
}