import { useState, useEffect } from 'react';

/**
 * Hook that returns window.MessengerExtensions if they are loaded,
 * and null otherwise.
 *
 * NOTE: index.html loads the extensions script. This hook simply
 * waits for the script to be loaded using the `window.extAsyncInit`
 * callback.
 */
export default function useMessengerExtensions() {
  const [MessengerExtensions, setMessengerExtensions] = useState(window.MessengerExtensions);

  // See documentation here:
  // https://developers.facebook.com/docs/messenger-platform/webview/extensions/
  useEffect(() => {
    if (MessengerExtensions) return;

    if (window.MessengerExtensions) {
      setMessengerExtensions(window.MessengerExtensions);
      return;
    }

    // MessengerExtensions SDK calls this function once it has loaded.
    window.extAsyncInit = () => setMessengerExtensions(window.MessengerExtensions);
  }, [MessengerExtensions, setMessengerExtensions]);

  return MessengerExtensions;
}
