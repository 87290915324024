import React, { useState, useEffect } from 'react';
import '../App.css';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { Box, Typography, Paper, Backdrop } from '@material-ui/core';


function ErrorPage(props) {
    const {errorMessage, errorType} = props
    /*
        errorTypes
      urlFieldMissing
      getConfigError

    */
    const theme = useTheme();
    const useStyles = makeStyles((theme) => ({
        content: {
          paddingBottom: '116px',
          minHeight: '100%',
        }
      }));
    const classes = useStyles();

    useEffect(() => {
    }, []);

    return (
        <>
        <Backdrop open={true}>
             <Paper style={theme.expiredMessagePaper}>
                <Typography variant='h7' style={theme.expiredMessage}>🚨 Error: {errorMessage}</Typography>
             </Paper>
         </Backdrop>
        </>
    );
}

export default ErrorPage;