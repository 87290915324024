import React, {useEffect, useMemo} from 'react';
import {
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
} from 'formik';
import { 
    FormControlLabel, 
    FormControl,
    FormGroup, 
    Typography} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles, useTheme  } from '@material-ui/core/styles';

const MyTextField = ({...props}) =>{
    return (
        <TextField 
        {...props}
        InputProps={{
            ...props.InputProps,
            startAdornment: (
                <InputAdornment position="start">
                <SearchIcon style={{color: '#646464',}} />
                </InputAdornment>
            ),
        }}
        />
    )
}

export default function DropdownField(props) {
    const { name, label, options, prices, setPrices, currency, setFieldValue } = props
    const { values } = useFormikContext(); // these are the current form answers
    const theme = useTheme();
    const currencyFormat = useMemo(
        // TODO: use actual user locale instead of assuming en-US
        () => new Intl.NumberFormat(navigator.language , { style: 'currency', minimumFractionDigits: 2, currency: currency || 'USD' }),
        [currency]
    );
    const [field] = useField(props);

    useEffect(() => { 
        if(!Object.keys(values).length){ return}
        let new_local_price = 0
        for (let v of values[name]){
            let price = options[v]?.attributes?.price || 0
            new_local_price = new_local_price + Number(price)
        }
        let newPrice = {...prices}
        newPrice[name] = new_local_price
        setPrices(newPrice)
    }, [values[name]]);

    return (
        <>
            {label && <Typography 
                        variant="h6"
                        for={name} 
                        color="primary"
                        style={theme.groupHeader}>
                            {label} <ErrorMessage name={name} render={msg => <div style={{  display: 'inline-block', color: 'red' }} >{msg}</div>} />
                        </Typography>}
                        
            <FormControl style={theme.dropdownField}>
                <Autocomplete
                    className="country-select"
                    name={name}
                    options={options}
                    getOptionLabel={option=> option.attributes?.text}
                    onChange={(e, value) => {
                        console.log(value)
                        setFieldValue(name, value ? value.key : "")
                    }}
                    renderInput={params => (
                        <Field 
                        component={MyTextField} 
                        {...params} 
                        name={name} 
                        variant="outlined" 
                        fullWidth 

                        />
                    )}
                    renderOption={(option) => (
                        <div
                        style={{ 
                            display: "flex",
                            justifyContent: "space-between", 
                            border: 'none',
                            width: '100%',
                            padding: '6px'

                        }}>
                            <div>{option.attributes?.text}</div>
                            <div style={{ 'color': '#646464',
                                        'right': '0', 
                                        }}>
                                {`${option.attributes?.price ? currencyFormat.format( option.attributes?.price || '') : ''}`}
                                </div>
                        </div>
                      )}
                    
                    />
            </FormControl>
        </>
    )
}
