import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Box, Typography} from '@material-ui/core';
import {
    Formik,
    Form as FormikForm,
    ErrorMessage,
    useFormikContext,
    useField,
    setFieldValue,
    Field
} from 'formik';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

function Control({ value, onChange, diff, Icon, name, min, max, ...props}) {
  return (
    <IconButton onClick={() => props.form.setFieldValue(name, (value + diff))} disabled={value + diff < min || value + diff > max}>
      <Icon />
    </IconButton>
  );
}

function MyNumericInput({ value, onChange, min, max, ...props }) {
  const theme = useTheme();
  return (
    <div style={theme.numericField} >
      <Control  Icon={RemoveCircleOutlineIcon} name={props.field.name} form={props.form} min={min} max={max} diff={-1} value={value} onChange={onChange} />
      <div style={theme.numericFieldValue}>{value}</div>
      <Control Icon={AddCircleOutlineIcon} name={props.field.name} form={props.form} min={min} max={max} diff={+1} value={value} onChange={onChange} />
    </div>
  );
}

export default function NumericField(props) {
    const { name, label, prices, setPriceMultiplier, min, max } = props
    const { values } = useFormikContext(); // these are the current form answers
    const theme = useTheme();
    const [field] = useField(props);

    useEffect(() => {  
      if(!Object.keys(values).length){ return}
      setPriceMultiplier(values[name])
    },[values[name]])

    return (
        <>
        {label && <Typography 
            variant="h6"
            for={name} 
            color="primary"
            style={theme.groupHeader}>
                {label}
            </Typography>}
       <Field 
       style={theme.MuiFormControlLabel}
        {...field}
        component={MyNumericInput}
        value={values[name]}
        min={min}
        max={max}
       >
        </Field>
        </>

    )
}
