import React, { useState, useEffect } from 'react';
import '../App.css';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { Box, Typography, Backdrop, Paper, Button } from '@material-ui/core';


function ExpiredPage(props) {
    const {message} = props
    /*
        errorTypes
      urlFieldMissing
      getConfigError

    */
    const theme = useTheme();
    const useStyles = makeStyles((theme) => ({
        content: {
          padding: '116px',
          minHeight: '100%',
        },
        
      }));
    const classes = useStyles();

    useEffect(() => {
    }, []);

    return (
        <>
         <Backdrop open={true}>
             <Paper style={theme.expiredMessagePaper}>
                <Typography variant='h7' style={theme.expiredMessage}>{message}</Typography>
             </Paper>
         </Backdrop>
        </>
    );
}

export default ExpiredPage;