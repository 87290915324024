import React, {useEffect, useMemo} from 'react';
import {
    Formik,
    Form as FormikForm,
    ErrorMessage,
    useFormikContext,
    useField
} from 'formik';
import { 
    Checkbox, 
    FormControlLabel, 
    FormGroup, 
    Typography} from '@material-ui/core';
import { makeStyles, useTheme  } from '@material-ui/core/styles';


const MyCheckboxHelper = ({label, ...props}) =>{
    return <>
            <Checkbox {...props} color="primary"/>{label}
        </>
} 
const MyCheckbox = ({label, priceLabel, ...props}) =>{
    const [field] = useField(props);
    const theme = useTheme();
    return <>
            <FormControlLabel 
            {...field} 
            style={{ 
                    display: "flex",
                    justifyContent: "space-between", 
                    }}
            labelPlacement="end"
            control={<MyCheckboxHelper label={label}/>} 
            label={<div style={ theme.priceText }>{priceLabel}</div>} />
            </>;
}

export default function CheckboxField(props) {
    const { name, label, options, prices, setPrices, currency } = props
    const { values } = useFormikContext(); // these are the current form answers
    const theme = useTheme();
    const currencyFormat = useMemo(
        // TODO: use actual user locale instead of assuming en-US
        () => new Intl.NumberFormat(navigator.language , { style: 'currency', minimumFractionDigits: 2, currency: currency || 'USD' }),
        [currency]
    );

    useEffect(() => {  
        if(!Object.keys(values).length){ return}
        let new_local_price = 0
        for (let v of values[name]){
            let price = options[v].attributes.price || 0
            new_local_price = new_local_price + Number(price)
        }
        let newPrice = {...prices}
        newPrice[name] = new_local_price
        setPrices(newPrice)
    }, [values[name]]);

    return (
        <>
            {label && <Typography 
                        variant="h6"
                        for={name} 
                        color="primary"
                        style={theme.groupHeader}>
                            {label} <ErrorMessage name={name} render={msg => <div style={{  display: 'inline-block', color: 'red' }} >{msg}</div>} />
                        </Typography>}
            <FormGroup style={{ width: '100%' }} >
                {options.map((optn, index) => 
                    <MyCheckbox 
                    key={optn.key} 
                    type="checkbox" 
                    name={name} 
                    value={optn.key} 
                    priceLabel= {`${optn.attributes.price ? currencyFormat.format( optn.attributes.price || '') : ''}`}
                    label={`${optn.attributes.text}`} />
                )}
            </FormGroup>
            
        </>
    )
}
