import React, { useState, useEffect, useMemo } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMessengerExtensions from './messengerExtensions';
import * as qs from 'query-string';
import Webview from './Webview/Webview'
import ErrorPage from './Errors/ErrorPage'
import ExpiredPage from './Errors/ExpiredPage'
import { setManychatFields, getConfig } from './api/api'
import defaultTheme from './theme';

const parsed = qs.parse(window.location.search);
let userID = parsed['userID'];
let time = parsed['time'];
let configHash = parsed['config_hash'] || "";
let configName = parsed['config_name'] || "";
let pageID = parsed['pageID'];
// ?config_hash=a25cfe30379923b8d7431f94579e63c4&userID=1975669872492219&pageID=650507971990003&time=1612908991465
const debug = false
if (debug){
  userID = '1975669872492219'
  time = 121323434
  configHash = 'a25cfe30379923b8d7431f94579e63c4'
  pageID = '650507971990003'
}

function App() {

    const [config, setConfig] = useState({});
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [errorType, setErrorType] = useState(undefined);
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const MessengerExtensions = useMessengerExtensions();

    const messengerBlue = {
      light: '#0078FF',
      main: '#0078FF',
      dark: '#0078FF',
      contrastText: '#ffffff',
    };

    const white = {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#ffffff',
    };

    const themeColor = config?.theme?.color?.main? config.theme.color: messengerBlue;

    const theme = createMuiTheme({
          ...defaultTheme,
          palette: {
            primary: themeColor,
            secondary: white,
          }
        })


    function _getConfig(){
      return getConfig(configHash, configName, pageID, userID, time)
    }

    async function _setManychatFields(
      values, config, formSchema, totalPrice){
      return setManychatFields(pageID, 
        userID, 
        values, 
        config, 
        formSchema, 
        totalPrice )
    }

    async function _setAndSendManychat(
      values, config, formSchema, totalPrice, flowID, doExpire
    ){
      return setManychatFields(pageID, 
        userID, 
        values, 
        config, 
        formSchema, 
        totalPrice, 
        flowID,
        time,
        doExpire )
    }

    useEffect(() => {
      if(!userID || !pageID || !time ){
        setErrorMessage("One of the required URL parameters is not present.")
        setErrorType("urlFieldMissing")
      }
        // Wait for messenger extensions to be loaded before making
        // any requests.
      if (!MessengerExtensions) return;
      (async () => {

        const [configResponse] = await Promise.all([
          _getConfig(),
          // add any other requests here and they'll be executed asynchronously
        ]);
        console.log(configResponse)
        if (!configResponse.success ) {
          setErrorMessage(configResponse.message);
          setErrorType("getConfigError")
          setIsLoading(false)
          return;
        }else{
          setIsExpired(configResponse.isExpired);
          setConfig(configResponse.config)
          setIsLoading(false)
        }
      })();
      },
      // eslint-disable-next-line
      [MessengerExtensions]
    );

    return (
      <ThemeProvider theme={theme} >
        <div className="App">
          {(() => {
          switch (true) {
            case isLoading:
              return (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress color="primary"></CircularProgress>
                </div>
              );
            case errorMessage !== undefined:
              return <ErrorPage 
                        errorMessage={errorMessage}
                        errorType={errorType}/>
            case isExpired:
              return (<ExpiredPage message={config.submit.button.expired_text ? config.submit.button.expired_text : "⚠️ This form has expired. ⚠️"}></ExpiredPage>);
            case !isLoading && errorMessage === undefined:
              return (
                <Webview
                config={config}
                setManychatFields = {_setManychatFields}
                setAndSendManychat = {_setAndSendManychat}
                isExpired = {isExpired}
                MessengerExtensions={MessengerExtensions}
              />
              );
            default:
              return null;
          }
          })()}
        </div>
      </ThemeProvider>
    );
}

export default App;