import React, {useEffect, useMemo} from 'react';
import {
    Formik,
    Field,
    Form as FormikForm,
    ErrorMessage,
    useFormikContext,
} from 'formik';
import { 
    MenuItem, 
    FormControlLabel, 
    FormControl,
    FormGroup, 
    Typography} from '@material-ui/core';
import { Select } from 'formik-material-ui';
import { makeStyles, useTheme  } from '@material-ui/core/styles';


const MyDropdownHelper = ({...props}) =>{
    return <>
            <div  color="primary">{props.label}</div>
        </>
} 
const MyDropdown = ({label, priceLabel, ...props}) =>{
    const theme = useTheme();
    return <>
            <FormControlLabel 
            style={{ 
                    display: "flex",
                    justifyContent: "space-between", 
                    border: 'none',
                    }}
            labelPlacement="end"
            control={<MyDropdownHelper {...props} label={label}/>} 
            label={<div style={ theme.priceText }>{priceLabel}</div>} />
            </>;
}

export default function DropdownField(props) {
    const { name, label, options, prices, setPrices, currency } = props
    const { values } = useFormikContext(); // these are the current form answers
    const theme = useTheme();
    const currencyFormat = useMemo(
        // TODO: use actual user locale instead of assuming en-US
        () => new Intl.NumberFormat(navigator.language , { style: 'currency', minimumFractionDigits: 2, currency: currency || 'USD' }),
        [currency]
    );

    useEffect(() => { 
        console.log(options)
        if(!Object.keys(values).length){ return}
        let new_local_price = 0
        for (let v of values[name]){
            let price = options[v]?.attributes?.price || 0
            new_local_price = new_local_price + Number(price)
        }
        let newPrice = {...prices}
        newPrice[name] = new_local_price
        setPrices(newPrice)
    }, [values[name]]);

    return (
        <>
            {label && <Typography 
                        variant="h6"
                        for={name} 
                        color="primary"
                        style={theme.groupHeader}>
                            {label} <ErrorMessage name={name} render={msg => <div style={{  display: 'inline-block', color: 'red' }} >{msg}</div>} />
                        </Typography>}
            <FormControl style={theme.dropdownField}>
            <Field
                component={Select}
                type="text"
                name={name} 
                select={true}
                >
                {options.map((optn, index) => 
                    <MenuItem 
                    color="primary"
                    value={optn.key} 
                    >
                    <MyDropdown 
                        priceLabel= {`${optn.attributes.price ? currencyFormat.format( optn.attributes.price || '') : ''}`}
                        label={`${optn.attributes.text}`}
                    />
                    </MenuItem>
                )}
            </Field>
            </FormControl>
        </>
    )
}
