import React, {useEffect, useMemo} from 'react';
import {
    Formik,
    Form as FormikForm,
    ErrorMessage,
    useFormikContext,
    useField
} from 'formik';
import { 
    FormControlLabel, 
    Radio, 
    RadioGroup,
    Typography} from '@material-ui/core';
import { makeStyles, useTheme  } from '@material-ui/core/styles';


const MyRadioHelper = ({label, ...props}) =>{
    return <>
            <Radio {...props} color="primary"/>{label}
        </>
} 
const MyRadio = ({label, priceLabel, ...props}) =>{
    const [field] = useField(props);
    const theme = useTheme();
    return <>
            <FormControlLabel 
            {...field} 
            style={{
                    display: "flex",
                    justifyContent: "space-between", 
                }}
            labelPlacement="end"
            control={<MyRadioHelper label={label}/>} 
            label={<div style={ theme.priceText }>{priceLabel}</div>} />
            </>;
}

export default function RadioField(props) {
    const { name, label, options, prices, setPrices, currency } = props
    const { values } = useFormikContext(); // these are the current form answers
    const theme = useTheme();
    const currencyFormat = useMemo(
        // TODO: use actual user locale instead of assuming en-US
        () => new Intl.NumberFormat(navigator.language , { style: 'currency', minimumFractionDigits: 2, currency: currency || 'USD' }),
        [currency]
      );

    useEffect(() => {   
        let new_local_price = 0
        if(!Object.keys(values).length){ return}
        if (values[name] !=="" ){
            let price = options[values[name]]?.attributes?.price || 0
            new_local_price = new_local_price + Number(price)
        }
        let newPrice = {...prices}
        newPrice[name] = new_local_price
        setPrices(newPrice)
    }, [values[name]]);

    return (
        <>
            {label && <Typography 
                        variant="h6"
                        for={name} 
                        color="primary"
                        style={theme.groupHeader}
                        >
                        {label}
                        </Typography>}
            <RadioGroup 
            >
                {options.map((optn, index) => 
                    <MyRadio 
                    key={optn.key} 
                    type="radio" 
                    name={name} 
                    value={optn.key}
                    priceLabel= {`${optn.attributes.price ? currencyFormat.format( optn.attributes.price || '') : ''}`}
                    label={`${optn.attributes.text}`}
                     />
                )}
            </RadioGroup>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

