import { grey, blueGrey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const BACKGROUND = blueGrey[50];
export const OFFWHITE = grey[50];
const BORDER = grey[300];
const GUTTER = 24;

export default createMuiTheme({
  typography: {
    fontFamily: 'Nunito',
  },
  mainheader:{

  },
  dropdownField:{
    minWidth: 120,
    width: '100%',
    overflow: 'hidden',
    borderTop: `1px solid ${BORDER}`,
    padding: `8px 8px 8px ${GUTTER}px`,
  },
  numericField:{
    borderTop: `1px solid ${BORDER}`,
    borderBottom: `1px solid ${BORDER}`,
    padding: `8px 8px 8px ${GUTTER}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  numericFieldValue:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '32px',
  },
  priceText:{
    color: '#646464',
    position: 'absolute', 
    right: '0', 
    paddingRight:'20px',
  },
  groupHeader:{
    background: OFFWHITE,
    textAlign: 'left',
    display: 'block',
    padding: `16px ${GUTTER}px`,
    boxSizing: 'border-box',
  },
  expiredMessage:{
    background: OFFWHITE,
    display: 'block',
    boxSizing: 'border-box',
  },
  expiredMessagePaper:{
    background: OFFWHITE,
    display: 'block',
    margin: `20px`,
    padding: '50px',
    boxSizing: 'border-box',
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        display:'none'
      }
    },
    MuiTypography: {
      h5: {
        fontWeight: 800,
      },
    },
    MuiBox: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
        background: BACKGROUND,
        padding: `16px ${GUTTER}px`,
        boxSizing: 'border-box',
      },
    },
    MuiFormGroup: {
      root: {
        width: '100%',
      },
    },
    MuiFormControl: {
      root: {
        boxSizing: 'border-box',
      },
    },
    MuiFormControlLabel: {
      root: {
        borderTop: `1px solid ${BORDER}`,
        '&:last-child': {
          borderBottom: `1px solid ${BORDER}`,
        },
        padding: `8px 8px 8px ${GUTTER}px`,
      },
      label: {
        marginLeft: 0,
        marginRight: 'auto',
      },
      labelPlacementStart: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
});
