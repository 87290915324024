import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';


export function Footer({ config, totalPrice }) {
    // TODO: hasPrice should be based on whether any price cols are configured
    const hasPrice = config.price_mc_field !== ""
    const currencyFormat = useMemo(
      // TODO: use actual user locale instead of assuming en-US
      () => new Intl.NumberFormat(navigator.language, { style: 'currency', minimumFractionDigits: 2, currency: config.currency || 'USD' }),
      [config.currency]
    );
    const { isValid, values } = useFormikContext();
  
    const formattedTotal = hasPrice ? currencyFormat.format(totalPrice) : null;
  
    const subtotalDisplay = config.submit.button.subtotal_display || 'button';
  
    const useStyles = makeStyles((theme) => ({
        stickyFooter: {
          position: 'sticky',
          background: 'white',
          bottom: 0,
          boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.12), 0 -2px 12px rgba(0, 0, 0, 0.05)',
        },
        subtotalRow: {
          padding: '24px 24px 0',
          display: 'flex',
          justifyContent: 'space-between',
        },
        subtle: {
          color: 'grey',
        },
      }));

      const css = useStyles();

    return (
      <div className={css.stickyFooter}>
        {hasPrice && subtotalDisplay === 'above_button' ? (
          <div className={css.subtotalRow}>
            <div className={css.subtle}></div>
            <div>{formattedTotal}</div>
          </div>
        ) : (
          ''
        )}
        <div
          style={{
            padding: 24,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            label="Submit"
            type="submit"
            style={{ textTransform: 'none', minHeight: '45px', fontSize: '15px' }}
          >
            <Typography color="secondary">{config.submit.button.caption}{` `} {hasPrice && subtotalDisplay === 'button' ? ` (${formattedTotal})` : ''}{/*{isValid ? '':" ⚠️"}*/}</Typography> 
            
          </Button>
        </div>
      </div>
    );
  }